h1 {
    font-size: 40px
}

h2 {
    font-size: 36px
}

h3 {
    font-size: 30px
}

h4 {
    font-size: 26px
}

h5 {
    font-size: 23px
}

h6 {
    font-size: 20px
}

h1,h2,h3,h4,h5,h6 {
    font-family: droid ;
}

.article{
 
    font-family: droid ;
  
}
/*
p {
    font-family: al ;
    font-size: 35px ;
}
*/

p {
    font-size: 35px ;
    $color: #000 ;
    font-family: ba ;
    color: black;
    
  /*  text-shadow:
        3px 3px 0 $color,
      -1px -1px 0 $color,  
       1px -1px 0 $color,
       -1px 1px 0 $color,
        1px 1px 0 $color;
        */
}

.fake-btn {

    border: none;
    display: inline-block;
    padding: 8px 16px;
    vertical-align: middle;
    overflow: hidden;
    text-decoration: none;
    color: inherit;
    background-color: inherit;
    text-align: center;
    white-space: nowrap
    
}





  .container { 
    
    border: none ; 
  }
  
  .vertical-center {
    margin-top: 50%;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    text-align: center!important;
  }

.mail {
    
    font-size: 40px;
    
    
}
/*
article {
    margin: 1em;
    max-width: 60em;
    min-width: 20em;
    height: 100vh;

    border-radius: 7em;
    padding: 1.5em;
    font: 16px "Open Sans", Verdana, Arial, Helvetica, sans-serif;
  }
  */

  .article_img {
    max-width: 20vw;
    margin-bottom: 1.25em;
    margin-top: 4em
  }

  .overlay {
    width: 100%;
    height: 100%;
    max-height: 100%;
    margin: 0;
    padding: 0;
    background: url("/assets/img/4.webp") ;
    background-size:100% 100%;
    background-repeat: no-repeat;
  }

